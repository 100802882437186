import type { HTMLAttributes, ElementType } from 'react';
import React from 'react';

const elStyle = {
  outline: 'none',
};

export interface IProps
  extends Omit<HTMLAttributes<HTMLElement>, 'aria-label' | 'onClick'>,
    Required<Pick<HTMLAttributes<HTMLElement>, 'aria-label' | 'onClick'>> {
  as?: ElementType;
}

/**
 * Use for visible, non-interactive elements that are clickable
 * @see https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-static-element-interactions.md
 * @see https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/click-events-have-key-events.md
 * @see https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/control-has-associated-label.md
 * @param {Object} props
 * @param {String} props.as Specify tag element (default: `div`)
 * @param {Function} props.onClick
 */
export function Clickable({ as: El = 'div', onClick, style = {}, ...props }: IProps) {
  return (
    <El
      onClick={onClick}
      onKeyUp={onClick}
      style={{ ...elStyle, ...style }}
      role="button"
      tabIndex="0"
      {...props}
    />
  );
}
Clickable.displayName = 'Clickable';
