import styled from 'styled-components';
import { media } from '$util/theme';

export default styled.section`
  .left-side,
  .right-side {
    & > div {
      width: 100%;
    }
  }
  ${media.up('lg')} {
    &.-left {
      .right-side {
        max-width: 720px;
      }
    }
    &.-right {
      .content {
        justify-content: flex-end;
      }
      .left-side {
        max-width: 720px;
        width: 100%;
      }
      .right-side {
        display: flex;
        justify-content: flex-end;
        > div {
          display: inline-block;
        }
      }
    }
  }
`;
