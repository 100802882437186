import type { ReactElement } from 'react';
import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

export interface IProps {
  large?: boolean;
  className?: string;
  children: React.ReactNode;
}

function Container({ large = false, className = '', ...props }: IProps): ReactElement {
  return (
    <div className={classNames(styles.container, 'container', large ? '-large' : '', className)} {...props} />
  );
}

Container.displayName = 'Container';
export default Container;
