import styled, { css } from 'styled-components';
import { media } from '$util/theme';
import type { MediaQueryBreakpoint } from '$util/theme/breakpoints';
import Section from '../Section';

export const GUTTER = { xs: 8, sm: 8, md: 8, lg: 16, xl: 16, xxl: 16 } as const;

export default styled(Section)`
  overflow: hidden;
  .container {
    > .Row {
      margin: 0 -${GUTTER.xs / 2}px;
      ${Object.entries(GUTTER).map(
        ([breakpoint, gutter]) =>
          breakpoint !== 'xs' &&
          css`
            ${media.up(breakpoint as MediaQueryBreakpoint)} {
              margin: 0 -${gutter / 2}px;
            }
          `
      )}
      > .Col {
        padding: ${GUTTER.xs / 2}px;
        ${Object.entries(GUTTER).map(
          ([breakpoint, gutter]) =>
            breakpoint !== 'xs' &&
            css`
              ${media.up(breakpoint as MediaQueryBreakpoint)} {
                padding: ${gutter / 2}px;
              }
            `
        )}
      }
    }
  }
`;
