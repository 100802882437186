import styled, { css } from 'styled-components';
import { vars, media } from '$util/theme';
import type { MediaQueryBreakpoint } from '$util/theme/breakpoints';
import type IProps from './props';

type TStyledProps = Pick<IProps, 'large' | 'stickySide'>;

const { mediaQueryBreakpoints, edgePaddingBreakpoints, sectionSpacingBreakpoints } = vars;

/**
 * Max width makes sure it will always have the minim padding,
 * even after the container size is exceeded and the edge space is determined
 * by the container being centered.
 */
const getMaxWidth = ({ large, stickySide }: TStyledProps) => {
  let size = 0;
  const { container, xxl } = edgePaddingBreakpoints;
  if (stickySide || !large) size = mediaQueryBreakpoints.container + container * 2;
  else if (large) size = mediaQueryBreakpoints.containerLarge + xxl * 2;
  return `${size}px`;
};

const makeEdgePadding = Object.entries(edgePaddingBreakpoints).map(([breakpoint, padding]) => {
  if (breakpoint === 'xs') {
    return css`
      padding-left: ${padding}px;
      padding-right: ${padding}px;
    `;
  }
  return css`
    ${media.up(breakpoint as MediaQueryBreakpoint)} {
      padding-left: ${padding}px;
      padding-right: ${padding}px;
    }
  `;
});

const makeSectionSpacing = Object.entries(sectionSpacingBreakpoints).map(([breakpoint, padding]) => {
  if (breakpoint === 'xs') {
    return css`
      padding-top: ${padding}px;
      padding-bottom: ${padding}px;
    `;
  }
  return css`
    ${media.up(breakpoint as MediaQueryBreakpoint)} {
      padding-top: ${padding}px;
      padding-bottom: ${padding}px;
    }
  `;
});

/**
 * Use this css on components that can't use Section
 * but still needs to behave like one
 */
export const cssSection = css`
  ${makeEdgePadding}
  ${makeSectionSpacing}
  @media (min-width: ${getMaxWidth}) {
    padding-left: unset;
    padding-right: unset;
  }
`;

const setStickyPadding = ({ stickySide }: TStyledProps) => {
  switch (stickySide) {
    case 'left':
      return css`
        padding-left: unset;
      `;
    case 'right':
      return css`
        padding-right: unset;
      `;
    default:
      return '';
  }
};

export default styled.section<TStyledProps>`
  ${cssSection}
  ${media.up('lg')} {
    ${setStickyPadding}
  }
`;
