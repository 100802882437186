import classNames from 'classnames';

import type { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react';
import styles from './index.module.scss';

const defaultElement: ElementType = 'span';
type TitleProps<E extends ElementType = typeof defaultElement> = ComponentPropsWithoutRef<E> & {
  /**
   * @default md
   */
  readonly size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /**
   * @default 3
   */
  readonly h?: 0 | 1 | 2 | 3 | 4 | 5;
  readonly light?: boolean;
  readonly children: ReactNode;
};

const headings: ElementType[] = ['span', 'h1', 'h2', 'h3', 'h4', 'h5'];

export function Title({ children, className, size = 'md', h = 3, light, ...props }: TitleProps): JSX.Element {
  const Heading: ElementType = headings[h] ?? defaultElement;
  const titleClass = () => {
    if (h > 0 || !size) return '';
    const lightClass = size === 'xl' && light ? styles.light : '';
    return [styles[size], lightClass];
  };
  return (
    <Heading className={classNames(className, 'title', styles.component, titleClass())} {...props}>
      {children}
    </Heading>
  );
}
Title.displayName = 'Title';
