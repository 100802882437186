import Head from 'next/head';
import { FONT_PRELOADS } from '$constants/font-preloads';
import { ResourceHints } from '$src-components/atoms/ResourceHints';

/**
 * Common `<head>` things to add on all pages that require a customer.
 * eg. pages from checkout flow; customer pages
 * Specific things like `<title>` should be added in page component.
 */
export function CustomerCommonHead(): JSX.Element {
  return (
    <Head>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

      <meta name="theme-color" content="#ffffff" />
      <meta name="robots" content="noindex, nofollow" />
      <link rel="apple-touch-icon" href="/manifest/apple-touch-icon.png" />
      <link rel="manifest" href="/manifest/site.webmanifest" />

      {/* on mobile preload above the fold common resources */}
      <ResourceHints resources={FONT_PRELOADS} />
    </Head>
  );
}
