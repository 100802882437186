import type { HTMLAttributes, ReactElement } from 'react';
import React from 'react';
import classNames from 'classnames';
import Styled from './styled';
import { getThemedBanner, BannerThemeTypes } from './themedBanner';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  readonly background?: string;
  readonly type?: BannerThemeTypes;
  readonly children: React.ReactNode;
}

function Banner({
  children,
  type = BannerThemeTypes.Default,
  background,
  className,
  ...props
}: Props): ReactElement {
  return (
    <Styled
      {...props}
      className={classNames(Banner.displayName, className)}
      style={getThemedBanner(type, background)}
      large
    >
      {children}
    </Styled>
  );
}

Banner.displayName = 'Banner';

export default Banner;
