import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export interface ILink {
  readonly name: string;
  readonly link: string;
  readonly type?: number;
}

export interface SimpleLinkProps extends Omit<ComponentPropsWithoutRef<'a'>, 'type'> {
  readonly href?: string;
  readonly text: string | ReactNode;
  // type can be 1, 2, 3, 4, 5
  readonly type?: number | undefined;
  readonly className?: string;
}

export function SimpleLink({ className, href, text, type, ...props }: SimpleLinkProps): JSX.Element {
  return (
    <a
      className={classNames(
        SimpleLink.displayName,
        styles.component,
        className,
        type && styles[`link-${type}`]
      )}
      href={href || '#'}
      {...props}
    >
      {text}
    </a>
  );
}
SimpleLink.displayName = 'SimpleLink';
