import type { HTMLAttributes } from 'react';
import React from 'react';
import classNames from 'classnames';
import Styled from './styled';

export interface Props extends HTMLAttributes<HTMLAnchorElement> {
  readonly href?: string;
  readonly target?: string;
  readonly type?: number;
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly rel?: string;
}

export function Link({ href, target, children, className, type = 1, rel, ...props }: Props): JSX.Element {
  return (
    <Styled
      href={href}
      className={classNames(`link link-${type}`, className)}
      target={target}
      rel={rel}
      {...props}
    >
      {children}
    </Styled>
  );
}
Link.displayName = 'Link';
