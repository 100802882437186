import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'antd';
import Container from '../Container';
import Styled from './styled';

export interface IProps {
  stickySide: 'left' | 'right';
  children: [React.ReactNode, React.ReactNode];
  className?: string;
}

function ContainerStickySide({
  stickySide,
  children: [LeftContent, RightContent],
  className = '',
  ...props
}: IProps) {
  return (
    <Styled className={classNames('ContainerStickySide', `-${stickySide}`, className)} {...props}>
      <Container large>
        <Row className="content">
          <Col className="left-side" lg={12}>
            <div>{LeftContent}</div>
          </Col>
          <Col className="right-side" lg={12}>
            <div>{RightContent}</div>
          </Col>
        </Row>
      </Container>
    </Styled>
  );
}

ContainerStickySide.displayName = 'ContainerStickySide';
export default ContainerStickySide;
