import styled, { css } from 'styled-components';
import { Input } from 'antd';
import { vars, defaultBorderRadius, pxToRem } from '$util/theme';

export const inputCss = css`
  width: 100%;
  min-width: 200px;
  height: 64px !important;
  padding: 15px 10px;
  border: 1px solid ${vars.quantumverse.reskinColors.neutral200};
  border-radius: ${vars.quantumverse.borderRadius.borderRadius12};
  font-size: ${pxToRem(15)};
  color: ${vars.quantumverse.reskinColors.neutral700};
  background-color: ${vars.quantumverse.reskinColors.white};
  &::placeholder {
    color: ${vars.placeholder};
  }
  &:focus {
    outline: none;
    border-color: ${vars.quantumverse.reskinColors.purple500} !important;
    box-shadow: 0 0 0 2px rgba(16, 130, 234, 0.2);
  }
  &:-webkit-autofill {
    &,
    &:hover,
    &:focus,
    &:active {
      /* https://stackoverflow.com/a/29350537/2099524 */
      transition: background-color 5000s ease-in-out 0s;
    }
  }
`;

export const InputStyled = styled(Input)`
  height: 64px;
  border: 1px solid ${vars.quantumverse.reskinColors.neutral200};
  border-radius: ${defaultBorderRadius};
  &.ant-input {
    ${inputCss}
    &.-floating {
      padding: 30px 10px 12px;
    }
  }
  .ant-input /* with prefix/suffix */ {
    ${inputCss}
    height: auto;
    &.-floating {
      padding: 30px 10px 12px;
    }
  }
  &.ant-input-affix-wrapper /* with prefix/suffix */ {
    .ant-input:not(.ant-input-disabled) {
      &:hover {
        border-color: ${vars.quantumverse.reskinColors.neutral200};
      }
    }
  }
`;

export const TextAreaStyled = styled(Input.TextArea)`
  ${inputCss}
  &.ant-input {
    display: block;
    height: 150px;
    background-color: ${vars.quantumverse.reskinColors.neutral100} !important;
  }
`;

export const FloatingLabel = styled.label`
  opacity: 0;
  z-index: 10;
  position: absolute;
  flex-basis: 100%;
  transform: translateY(0);
  pointer-events: none;

  &.-show {
    color: ${vars.quantumverse.reskinColors.purple500};
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(18)};
    display: block;
    opacity: 1;
    padding-top: 5px;
    padding-left: 10px;
    transform: translateY(3px);
    transition-duration: 0.5s;
    transition-property: opacity, transform;
  }
`;
