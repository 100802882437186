import React from 'react';
import classNames from 'classnames';
import { pick, mapValues } from 'lodash';
import { Row as $Row, Col as $Col } from 'antd';
import type { RowProps as $IRowProps } from 'antd/lib/grid/row';
import type { ColProps as $IColProps } from 'antd/lib/grid/col';
import type ISectionProps from '../Section/props';
import Styled, { GUTTER } from './styled';

export { GUTTER };

export interface IGridProps extends ISectionProps {
  /**
   * Change default HTML tag. By default it is `section`.
   * @see https://styled-components.com/docs/api#forwardedas-prop
   */
  forwardedAs?: string;
}

/**
 * `Grid` is a `Section` component which contains `Row` and `Col` children
 * https://app.abstract.com/projects/7d47da54-325c-4b74-94e2-965b0553576e/branches/master/collections/56837844-0720-40ff-a0c1-8baa5b117994
 *
 * @deprecated use `src/components/atoms/Grid`
 */
function Grid({ className = '', forwardedAs, ...props }: IGridProps) {
  return (
    <Styled className={classNames('Grid', className)} forwardedAs={forwardedAs as undefined} {...props} />
  );
}
Grid.displayName = 'Grid';
export default Grid;

/**
 * DON'T use prop `gutter`, it sets margins from js.
 * If you need custom gutters set them in CSS.
 */
export type TRowProps = Omit<$IRowProps, 'gutter'>;

/**
 * Row is of type flex
 *
 * @deprecated use from `src/components/atoms/Grid`
 */
function Row({ className = '', ...props }: TRowProps) {
  return <$Row className={classNames('Row', className)} {...props} />;
}
Row.displayName = 'Row';
Grid.Row = Row;

const COL_GRID_PROPS = ['span', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'offset', 'push', 'pull'];
const doubleRecursiveProps = (obj: Record<string, unknown>): Record<string, unknown> =>
  mapValues(obj, (val: Record<string, unknown> | number) => {
    if (typeof val === 'object') return doubleRecursiveProps(val);
    return val * 2;
  });

/**
 * It's a 12 column grid instead of the default 24 in antd
 *
 * @deprecated use from `src/components/atoms/Grid`
 */
function Col({ className = '', ...props }: $IColProps) {
  const gridProps = doubleRecursiveProps(pick(props, COL_GRID_PROPS));
  // eslint-disable-next-line react/jsx-pascal-case
  return <$Col className={classNames('Col', className)} {...props} {...gridProps} />;
}
Col.displayName = 'Col';
Grid.Col = Col;
