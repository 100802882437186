import styled from 'styled-components';
import { vars, pxToRem } from '$util/theme';

export default styled.a`
  font-size: ${pxToRem(14)};
  text-decoration: underline;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:visited {
    color: ${vars.quantumverse.reskinColors.brandPurple};
    text-decoration: none;
    font-weight: ${vars.fontWeight.regular};
  }

  &.link-1 {
    color: ${vars.quantumverse.reskinColors.neutral300};
    text-decoration: underline;
    &:hover {
      color: ${vars.quantumverse.reskinColors.purple500};
    }
  }

  &.link-2 {
    text-decoration: underline solid ${vars.quantumverse.reskinColors.purple500};
    color: ${vars.quantumverse.reskinColors.purple500};
    &:hover {
      text-decoration: underline solid ${vars.transparent};
    }
  }

  &.link-3 {
    text-decoration: underline solid ${vars.transparent};
    color: ${vars.quantumverse.reskinColors.neutral800};
    &:hover {
      text-decoration: underline solid ${vars.quantumverse.reskinColors.neutral800};
    }
  }

  &.link-4 {
    color: ${vars.quantumverse.reskinColors.neutral800};
    text-decoration: underline;
    &:hover {
      color: ${vars.quantumverse.reskinColors.purple500};
    }
  }

  &.link-5 {
    text-decoration: underline solid ${vars.transparent};
    color: ${vars.red};
    font-weight: ${vars.fontWeight.bold};
    &:hover {
      text-decoration: underline solid ${vars.red};
    }
  }
`;
