import styled from 'styled-components';
import Section from '$components/layout/Section';
import { pxToRem, fontWeight, media } from '$util/theme';

export default styled(Section)`
  padding-top: 0;
  padding-bottom: 0;
  height: 60px;
  display: flex;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${pxToRem(13)};
    font-weight: ${fontWeight.semiBold};
    ${media.up('md')} {
      font-size: ${pxToRem(14)};
    }
  }
`;
