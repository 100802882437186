import type { CSSProperties } from 'react';
import { vars } from '$util/theme';

export enum BannerThemeTypes {
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
  Default = 'default',
}

export const getThemedBanner = (type: BannerThemeTypes, background?: string): CSSProperties => {
  if (background) {
    return { background };
  }
  switch (type) {
    case 'info':
      return {
        background: vars.purple500ToPurple300,
        color: vars.quantumverse.reskinColors.white,
      };
    case 'warning':
      return {
        background: vars.orange,
      };
    case 'danger':
      return {
        background: vars.red,
        color: vars.quantumverse.reskinColors.white,
      };
    case 'default':
    default:
      return {
        background: vars.quantumverse.reskinColors.green100,
      };
  }
};
