import styled from 'styled-components';
import { Input } from 'antd';
import { vars } from '$util/theme';

export const InputCaptionReskinStyled = styled.span<{ reskinTheme: 'light' | 'dark' }>`
  display: inline-block;

  font-family: 'Rubik';
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin-top: 10px;
  color: ${(props) =>
    props.reskinTheme === 'dark'
      ? vars.quantumverse.reskinColors.neutral200
      : vars.quantumverse.reskinColors.neutral300};

  &.-error {
    color: ${vars.quantumverse.reskinColors.red};
  }
`;

/**
 * The reason for tangling the label styles with input is for easier extraction
 * The storybook file will act as the source of truth for the designs
 * Storybook: https://styleguide.fixter123.net/?path=/story/components-basic-input--input-field
 */
export const InputLabelReskinStyled = styled.label<{ reskinTheme: 'light' | 'dark' }>`
  display: inline-block;

  font-family: 'Jokker';
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) =>
    props.reskinTheme === 'dark'
      ? vars.quantumverse.reskinColors.white
      : vars.quantumverse.reskinColors.neutral500};

  margin-bottom: 10px;
`;

export const InputReskinStyled = styled(Input)`
  padding: 1px 12px;
  border-radius: 12px;

  /* Reset AntD transition */
  transition: all 0s ease 0s;
  height: 52px;

  .ant-input {
    font-family: 'Rubik';
    font-weight: 500;
    line-height: 24px;
    font-size: 16px !important;

    padding: 12px 0 !important;
  }

  .ant-input-prefix {
    margin-right: 10px;
  }

  .ant-input-suffix {
    margin-left: 10px;
  }

  &.dark {
    background: ${vars.quantumverse.reskinAlphas.alphaWhite20};
    border: 1px solid ${vars.quantumverse.reskinColors.neutral200};

    .ant-input-prefix .qvIcon,
    .ant-input-suffix .qvIcon {
      color: ${vars.quantumverse.reskinColors.white};
    }

    .ant-input {
      background: transparent;
      color: ${vars.quantumverse.reskinColors.white};

      &::placeholder {
        color: ${vars.quantumverse.reskinColors.white};
      }
    }

    /* Hover state - effects only when input is not focused or disabled */
    &:hover:not(.ant-input-affix-wrapper-focused):not(.-disabled) {
      border: 1px solid ${vars.quantumverse.reskinAlphas.alphaWhite20};
      box-shadow: 0px 0px 0px 2px #c3b6fb;
    }

    /* Focused state */
    &.ant-input-affix-wrapper-focused {
      border: 2px solid ${vars.quantumverse.reskinColors.white};
      box-shadow: 0px 0px 0px 2px #c3b6fb;
      /* Compensate border increase */
      padding: 0 11px;
    }

    /* Valid state */
    &.-valid {
      &:not(.ant-input-affix-wrapper-focused) {
        border: 1px solid ${vars.quantumverse.reskinAlphas.alphaWhite70};
      }

      .ant-input-suffix .qvIcon {
        color: ${vars.quantumverse.reskinColors.white};
      }
    }

    /* Error state */
    &.-error:not(.ant-input-affix-wrapper-focused) {
      border: 1px solid ${vars.quantumverse.reskinColors.red};
    }

    /* Disabled state */
    &.-disabled {
      border: 1px solid ${vars.quantumverse.reskinAlphas.alphaWhite50};

      .ant-input {
        &::placeholder {
          color: ${vars.quantumverse.reskinAlphas.alphaWhite50};
        }
      }
    }
  }

  &.light {
    background: ${vars.quantumverse.reskinColors.white};
    border: 1px solid ${vars.quantumverse.reskinColors.neutral200};

    .ant-input-prefix .qvIcon {
      color: ${vars.quantumverse.reskinColors.neutral300};
    }

    .ant-input {
      background: transparent;
      color: ${vars.quantumverse.reskinColors.neutral500};

      &::placeholder {
        color: ${vars.quantumverse.reskinColors.neutral300};
      }
    }

    /* Hover state - effects only when input is not focused or disabled */
    &:hover:not(.ant-input-affix-wrapper-focused):not(.-disabled) {
      border: 1px solid ${vars.quantumverse.reskinColors.purple300};
      box-shadow: 0px 0px 0px 2px #c3b6fb;
    }

    /* Focused state */
    &.ant-input-affix-wrapper-focused {
      border: 2px solid ${vars.quantumverse.reskinColors.purple500};
      box-shadow: 0px 0px 0px 2px #c3b6fb;
      /* Compensate border increase */
      padding: 0 11px;

      .ant-input-prefix .qvIcon {
        color: ${vars.quantumverse.reskinColors.neutral500};
      }
    }

    /* Valid state */
    &.-valid {
      &:not(.ant-input-affix-wrapper-focused) {
        border: 1px solid ${vars.quantumverse.reskinColors.neutral200};
      }

      .ant-input-prefix .qvIcon {
        color: ${vars.quantumverse.reskinColors.neutral500};
      }

      .ant-input-suffix .qvIcon {
        color: ${vars.quantumverse.reskinColors.brandBrightGreen};
      }
    }

    /* Error state */
    &.-error:not(.ant-input-affix-wrapper-focused) {
      border: 1px solid ${vars.quantumverse.reskinColors.red};
    }

    /* Disabled state */
    &.-disabled {
      background: ${vars.quantumverse.reskinColors.neutral100};
      border: 1px solid ${vars.quantumverse.reskinColors.neutral200};

      .ant-input {
        &::placeholder {
          color: ${vars.quantumverse.reskinColors.neutral300};
        }
      }
    }
  }
`;

export const TextAreaReskinStyled = styled(Input.TextArea)`
  padding: 12px;
  border-radius: 12px;

  display: block;

  &.ant-input {
    height: 150px;
    /* Reset AntD transition */
    transition: all 0s ease 0s;
  }

  &.light {
    background: ${vars.quantumverse.reskinColors.white};
    border: 1px solid ${vars.quantumverse.reskinColors.neutral200};

    /* Hover state - effects only when input is not focused or disabled */
    &:hover:not(:focus):not(.-disabled) {
      border: 1px solid ${vars.quantumverse.reskinColors.purple300};
      box-shadow: 0px 0px 0px 2px #c3b6fb;
    }

    /* Focused state */
    &:focus {
      border: 2px solid ${vars.quantumverse.reskinColors.purple500};
      box-shadow: 0px 0px 0px 2px #c3b6fb;
      /* Compensate border increase */
      padding: 11px;
    }
  }

  &.dark {
    background: ${vars.quantumverse.reskinAlphas.alphaWhite20};
    border: 1px solid ${vars.quantumverse.reskinColors.neutral200};

    /* Hover state - effects only when input is not focused or disabled */
    &:hover:not(:focus):not(.-disabled) {
      border: 1px solid ${vars.quantumverse.reskinAlphas.alphaWhite20};
      box-shadow: 0px 0px 0px 2px #c3b6fb;
    }

    /* Focused state */
    &:focus {
      border: 2px solid ${vars.quantumverse.reskinColors.white};
      box-shadow: 0px 0px 0px 2px #c3b6fb;
      /* Compensate border increase */
      padding: 11px;
    }
  }
`;
