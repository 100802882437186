import dynamic from 'next/dynamic';
import classNames from 'classnames';
import type { ChangeEvent, HTMLAttributes } from 'react';
import React, { useEffect, useState } from 'react';
import { getShortTextByKey } from '$cms/getDataByKey';
import Input from '$components/composite/Form/Input';
import { useAppContext } from '$util/AppContext';
import type { CmsContentDictionary } from '$cms/index';

const PasswordStrengthBar = dynamic(() => import('react-password-strength-bar'));

interface State {
  readonly isValid: boolean;
  readonly password?: string;
  readonly score: number;
}

interface PasswordInputProps extends HTMLAttributes<HTMLDivElement> {
  readonly placeholder?: string;
  readonly minLength?: number;
  readonly minScore?: number;
  readonly checkValidity?: (valid: boolean) => void;
}

function showPasswordStrength(checkValidity: PasswordInputProps['checkValidity'], password?: string) {
  return typeof checkValidity === 'function' && password !== undefined && password.length > 0;
}

const getCmsData = (cmsData: CmsContentDictionary) => {
  const getShortText = getShortTextByKey.bind(cmsData);

  return {
    tooWeak: getShortText('password.tooWeak', 'Too weak'),
    weak: getShortText('password.weak', 'Too weak'),
    okay: getShortText('password.okay', 'Okay'),
    good: getShortText('password.good', 'Good'),
    strong: getShortText('password.strong', 'Strong'),
    tooShort: getShortText('password.tooShort', 'Too short'),
  };
};

function PasswordInput({
  minScore = 2,
  minLength = 8,
  checkValidity,
  className,
  ...props
}: PasswordInputProps): JSX.Element {
  const AppContext = useAppContext();
  const {
    cmsContent: { dictionary },
  } = AppContext;

  const { tooWeak, weak, okay, good, strong, tooShort } = getCmsData(dictionary);

  const [state, setState] = useState<State>({ isValid: false, password: undefined, score: 0 });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event?.target?.value;
    setState((prev) => ({ ...prev, password }));
  };

  const onChangeScore = (score: number) => {
    setState((prev) => ({ ...prev, score, isValid: score >= minScore }));
  };

  useEffect(() => {
    if (typeof checkValidity === 'function') checkValidity(state.isValid);
  }, [checkValidity, state]);
  return (
    <div className={classNames('PasswordInput', className)}>
      <Input type="password" onChange={onChange} autoComplete="off" {...props} />
      {showPasswordStrength(checkValidity, state.password) && (
        <PasswordStrengthBar
          password={state.password || ''}
          minLength={minLength}
          scoreWords={[tooWeak, weak, okay, good, strong]}
          shortScoreWord={tooShort}
          onChangeScore={onChangeScore}
        />
      )}
    </div>
  );
}

PasswordInput.displayName = 'PasswordInput';

export { PasswordInput };
