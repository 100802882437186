import type { ReactElement } from 'react';
import React from 'react';
import classNames from 'classnames';
import Container from '../Container';
import type { IProps as IContainerStickySideProps } from '../ContainerStickySide';
import ContainerStickySide from '../ContainerStickySide';
import type IProps from './props';
import Styled from './styled';

/**
 * @deprecated use `src/components/atoms/ContentComponentSection`
 */
function Section({ children, large, stickySide, className = '', ...props }: IProps): ReactElement {
  return (
    <Styled className={classNames('Section', className)} large={large} stickySide={stickySide} {...props}>
      {stickySide ? (
        <ContainerStickySide stickySide={stickySide}>
          {children as IContainerStickySideProps['children']}
        </ContainerStickySide>
      ) : (
        <Container large={large}>{children}</Container>
      )}
    </Styled>
  );
}

Section.displayName = 'Section';
export default Section;
