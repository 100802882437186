import React, { useMemo } from 'react';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { initializeApollo } from '$util/apollo/client';
import type { AnyObject } from '$util/types';

export const useApollo = (initialState?: AnyObject): ApolloClient<NormalizedCacheObject> => {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
};

export interface WithApolloProps {
  initialApolloState?: AnyObject;
}

/**
 * Adds apollo provider.
 * @note Use at page level.
 */
export const withApollo =
  <P extends AnyObject>(Component: React.ComponentType<P>) =>
  // eslint-disable-next-line react/display-name, react/function-component-definition
  ({ initialApolloState, ...props }: P & WithApolloProps): JSX.Element => {
    const apolloClient = useApollo(initialApolloState);
    return (
      <ApolloProvider client={apolloClient}>
        <Component {...(props as P)} />
      </ApolloProvider>
    );
  };
