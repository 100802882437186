import React, { createContext, useState, useMemo } from 'react';
import { useId } from 'react-id-generator';
import classNames from 'classnames';
import type {
  FieldInputProps as $IFieldInputProps,
  FieldMetaProps as $IFieldMetaProps,
  FieldHelperProps as $IFieldHelperProps,
} from 'formik';
import { useFormikContext, useField } from 'formik';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import type { FormItemProps as $IFormItemProps } from 'antd/lib/form/FormItem';
import FormItemAntd from 'antd/lib/form/FormItem';
import styles from './FormItem.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TUseField = [$IFieldInputProps<any>, $IFieldMetaProps<any>, $IFieldHelperProps<any>];

export interface IProviderValue {
  field: TUseField;
  forLabel: string;
  setFocussed: (focussed: boolean) => void;
}

/**
 * Pass formik field to children through context
 */
export const FormItemContext = createContext({} as IProviderValue);

export interface IFormItemProps extends $IFormItemProps {
  name: string;
  showHelp?: boolean;
  showErrorsOnSubmit?: boolean; // false to show them on change instead
  children: React.ReactNode;
}

function FormItem({
  name,
  showHelp = true,
  showErrorsOnSubmit = true,
  children,
  label = '',
  className = '',
  ...props
}: IFormItemProps) {
  const { submitCount } = useFormikContext();
  const field = useField(name);
  const [focussed, setFocussed] = useState(false);
  const [itemId] = useId(undefined, name); // generated unique id (CSR&SSR) used to point label to input
  const forLabel = useMemo(() => (label ? itemId : ''), [label, itemId]);

  const { value } = field[0];
  const { error, touched } = field[1];
  const isValid = value && !error && touched;
  let validateStatus: IFormItemProps['validateStatus'] = '';
  if (error) validateStatus = 'error';
  else if (isValid) validateStatus = 'success';

  const displayErrors = showHelp && error && (!showErrorsOnSubmit || submitCount > 0);
  const providerValue = useMemo(() => {
    return {
      field,
      forLabel,
      setFocussed,
    };
  }, [field, forLabel]);
  return (
    <FormItemAntd
      label={
        label && (
          <>
            {label}
            {isValid && <CheckCircleFilled />}
          </>
        )
      }
      htmlFor={forLabel}
      className={classNames(styles.FormItem, 'FormItem', className, {
        '-focussed': focussed,
        [`-${validateStatus}`]: validateStatus,
      })}
      validateStatus={validateStatus}
      help={displayErrors && <div className="item-error">{error}</div>}
      {...props}
    >
      <FormItemContext.Provider value={providerValue}>{children}</FormItemContext.Provider>
    </FormItemAntd>
  );
}
FormItem.displayName = 'FormItem';
export default FormItem;
