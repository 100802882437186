import type { MutableRefObject, ReactNode } from 'react';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import type { FormikConfig as IFormikConfig, FormikProps, FormikValues } from 'formik';
import { Formik } from 'formik';
import type { FormProps as $IFormProps } from 'antd/lib/form';
import { debounce } from 'lodash';
import FormAnt from 'antd/lib/form';
import styles from './index.module.scss';

export type IFormContext = FormikProps<FormikValues> | undefined;

export interface IFormProps extends $IFormProps {
  formik: IFormikConfig<FormikValues>;
  /**
   * Pass FormikProps outside of the component using a ref.
   */
  formContext?: MutableRefObject<FormikProps<FormikValues> | undefined>;
}

/**
 * Needs the antd css form manually imported
 * import 'antd/lib/form/style/index.css'; (or less)
 */
function Form({ formik, className = '', formContext, children, ...props }: IFormProps): JSX.Element {
  const [form] = FormAnt.useForm();

  const { onSubmit } = formik;
  /**
   * Debounce submit per 1s to avoid spam submits.
   */
  const debounceSubmit = useMemo(
    () => debounce(onSubmit, 1000, { leading: true, trailing: false }),
    [onSubmit]
  );

  return (
    <Formik validateOnBlur={false} {...formik} onSubmit={debounceSubmit}>
      {(params) => {
        // eslint-disable-next-line no-param-reassign
        if (typeof formContext !== 'undefined') formContext.current = params;
        return (
          <FormAnt
            className={classNames(styles.FormAnt, 'Form', className)}
            form={form}
            onSubmitCapture={params.handleSubmit}
            layout="vertical"
            {...props}
          >
            {children as ReactNode}
          </FormAnt>
        );
      }}
    </Formik>
  );
}
Form.displayName = 'Form';
export default Form;
