import React from 'react';
import classNames from 'classnames';
import type { InputProps as AntdInputProps, TextAreaProps } from 'antd/lib/input';
import { InputStyled, TextAreaStyled, FloatingLabel } from './styled';
import { InputReskinStyled, TextAreaReskinStyled } from './reskinStyled';

export type { TextAreaProps };

export interface InputProps extends AntdInputProps {
  floating?: boolean;
  label?: string;
  reskin?: boolean;
  reskinTheme?: 'light' | 'dark';
  isValid?: boolean;
  isError?: boolean;
}

export function Input({
  className = '',
  floating = false,
  reskin = false,
  reskinTheme = 'light',
  label = '',
  isValid,
  isError,
  ...props
}: InputProps): JSX.Element {
  const { disabled } = props;
  if (reskin)
    return (
      <InputReskinStyled
        className={classNames(
          'Input',
          reskinTheme,
          isError && '-error',
          isValid && '-valid',
          disabled && '-disabled',
          floating && '-floating',
          className
        )}
        {...props}
      />
    );
  return (
    <>
      <FloatingLabel className={classNames(floating && '-show')}>{label}</FloatingLabel>
      <InputStyled className={classNames('Input', floating && '-floating', className)} {...props} />
    </>
  );
}
Input.displayName = 'Input';

interface FixterTextAreaProps extends TextAreaProps {
  reskin?: boolean;
  reskinTheme?: 'light' | 'dark';
}

export function TextArea({
  className = '',
  reskin = false,
  reskinTheme = 'dark',
  ...props
}: FixterTextAreaProps): JSX.Element {
  if (reskin)
    return <TextAreaReskinStyled className={classNames('TextArea', reskinTheme, className)} {...props} />;
  return <TextAreaStyled className={classNames('TextArea', className)} {...props} />;
}
TextArea.displayName = 'TextArea';
