import React, { useContext, forwardRef } from 'react';
import type { InputProps as AntdInputProps } from 'antd/lib/input';
import { Input as AntdInput } from '$components/basic/Input';
import { FormItemContext } from './FormItem';

export interface InputProps extends AntdInputProps {
  disabled?: boolean;
  floating?: boolean;
  label?: string;
}

const Input = forwardRef(
  (
    { onChange, onBlur, disabled, floating, label, ...props }: InputProps,
    ref: React.Ref<typeof AntdInput>
  ) => {
    const { field, forLabel, setFocussed } = useContext(FormItemContext);
    const [{ name, value, onChange: fieldOnChange, onBlur: fieldOnBlur }] = field;
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      fieldOnChange(e);
      if (typeof onChange === 'function') onChange(e);
    };
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocussed(false);
      fieldOnBlur(e);
      if (typeof onBlur === 'function') onBlur(e);
    };
    const handleFocus = () => {
      setFocussed(true);
    };
    const extendProps = { ...props };
    if (forLabel) extendProps.id = forLabel;
    return (
      <AntdInput
        // @ts-ignore type doesn't recognize forward ref
        ref={ref}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        floating={floating}
        label={label}
        disabled={disabled}
        reskin
        {...extendProps}
      />
    );
  }
);
Input.displayName = 'Input';
export default Input;

Input.defaultProps = {
  disabled: false,
  floating: false,
  label: '',
};
