import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { cookieOptions } from '$util/cookieOptions';
import { useRenderAfterHydration } from '$util/hooks/useRenderAfterHydration';
import Styled from './styled';

function AdminWarning({ className = '' }) {
  const [totalExpTime, setTotalExpTime] = useState('');
  const fixterAdminLogin = Cookies.getJSON('fixterAdminLogin') || {};

  const { customer, adminMeta } = fixterAdminLogin || {};

  const { firstName, lastName } = customer || {};
  const { bookingId, vrm, last4, exp, maiaUserId } = adminMeta || {};

  const clientSideRenderReady = useRenderAfterHydration();

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (maiaUserId) {
      interval = setInterval(() => {
        const expSeconds = exp - Math.floor(Date.now() / 1000);
        const minutes = Math.floor(expSeconds / 60);
        const seconds = expSeconds - minutes * 60;
        if (expSeconds >= 0) {
          setTotalExpTime(`${minutes} min. ${seconds} sec.`);
        } else {
          Cookies.remove('fixterAdminLogin', cookieOptions.fixterAdminLogin);
          Cookies.remove('fixterAccessToken', cookieOptions.fixterAccessToken);
          Cookies.remove('fixterSessionId', cookieOptions.fixterSessionId);
          window.location.href = window.location.origin;
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [exp, maiaUserId]);

  if (!maiaUserId) return null;

  const fullName = `${firstName} ${lastName}`;
  const bookingIdAndVRM = `#${bookingId}, ${vrm}`;

  if (!clientSideRenderReady || !totalExpTime) return null;
  return (
    <>
      <Styled className={classNames('AdminWarning', className)}>
        <ExclamationCircleOutlined /> Warning
        <p>
          You&apos;re logged in as &quot;<b>{fullName}</b>&quot; from <b>Booking {bookingIdAndVRM}</b>. Card
          number ending in <b>{last4}</b>
        </p>
        <p>
          This session will expire in <b>{totalExpTime}</b>
        </p>
      </Styled>
      <div style={{ paddingTop: 90 }} />
    </>
  );
}

AdminWarning.displayName = 'AdminWarning';
export default AdminWarning;
