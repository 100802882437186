import styled from 'styled-components';

export default styled.div`
  text-align: center;
  background: #fffbe6;
  padding: 10px 0;
  width: 100%;
  position: fixed;
  z-index: 100;

  .anticon {
    color: gold;
    font-size: large;
  }

  p {
    margin: 0;
  }
`;
