import type { Context } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { IdentityProfile, IdentityCheck } from './index';
import { QUERY_GET_IDENTITY_PROFILE, QUERY_CHECK_IDENTITY } from './index';

export interface IIdentityProfileQuery {
  getIdentityProfile: IdentityProfile;
}

export interface CheckIdentityQuery {
  checkIdentity: IdentityCheck;
}

/**
 * Here we can fetch the identity of the "thing" claiming to have
 * a valid access token.
 */
export const useIdentity = (context?: Context) => {
  const authContext = context ?? {
    waitAuthentication: true,
  };
  const { data, ...res } = useQuery<IIdentityProfileQuery>(QUERY_GET_IDENTITY_PROFILE, {
    context: authContext,
    ssr: false,
    skip: !authContext.waitAuthentication && !authContext.needsAuthentication,
  });
  return { data: data?.getIdentityProfile, ...res };
};

export const useCheckIdentity = () => {
  const { data, ...res } = useQuery<CheckIdentityQuery>(QUERY_CHECK_IDENTITY, {
    ssr: false,
  });
  return { data: data?.checkIdentity ?? { isAuthenticated: false }, ...res };
};
